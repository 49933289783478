import { ReactElement, ReactNode } from "react";
import { styled } from "@mui/material/styles";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
    Box,
    Divider,
    IconButton,
    SxProps,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { isDarkMode } from "@convin/utils/helper/common.helper";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    "& .MuiDialog-paper": {
        maxWidth: "90vw",
        minWidth: "0px",
        boxShadow: "none",
        display: "flex",
        flexDirection: "column",
        oveflow: "hidden",
    },
}));

interface BootstrapDialogTitleProps {
    children: ReactNode;
    onClose?: () => void;
    sx?: SxProps<Theme>;
    [key: string]: unknown;
}

export function BootstrapDialogTitle({
    children,
    onClose,
    sx,
    ...other
}: BootstrapDialogTitleProps): ReactElement {
    const theme = useTheme();
    return (
        <DialogTitle
            sx={{ m: 0, p: 3, position: "relative", ...sx }}
            {...other}
        >
            <Box
                sx={{
                    ...(isDarkMode(theme) && {
                        color: "#fff",
                    }),
                }}
            >
                {children}
            </Box>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 16,
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: (theme) => theme.palette.textColors[666],
                    }}
                >
                    <Close />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

interface CustomModalProps extends Omit<DialogProps, "title"> {
    open: boolean;
    handleClose?: () => void;
    children: ReactElement;
    dialogContentSx?: SxProps<Theme>;
    titleSx?: SxProps<Theme>;
    footer?: ReactNode;
    showFooter?: boolean;
    header?: ReactNode;
    subTitle?: string;
    title?: ReactNode | string;
}

export default function CustomModal({
    open = false,
    // setOpen = () => {},
    // handleClickOpen = () => {},
    handleClose = () => {
        return;
    },
    children,
    titleSx,
    title = "Modal",
    subTitle,
    dialogContentSx = {},
    footer = null,
    showFooter = true,
    header,
    ...rest
}: CustomModalProps): ReactElement {
    return (
        <BootstrapDialog
            onClose={(event, reason) => {
                if (reason !== "backdropClick") handleClose();
            }}
            aria-labelledby="customized-dialog-title"
            open={open}
            {...rest}
        >
            <Box className="flex-shrink-0">
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                    sx={titleSx}
                    className="flex-shrink-0"
                >
                    {header ?? title}
                    <Typography
                        variant="small"
                        className="font-normal"
                        sx={{ display: "block" }}
                    >
                        {subTitle}
                    </Typography>
                </BootstrapDialogTitle>
            </Box>
            <Divider />
            <DialogContent sx={dialogContentSx}>{children}</DialogContent>
            {showFooter && (
                <DialogActions sx={{ px: 4, py: "16px !important" }}>
                    {footer}
                </DialogActions>
            )}
        </BootstrapDialog>
    );
}
